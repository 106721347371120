.ui.celled.grid > .column:not(.row),
.ui.celled.grid > .row > .column.no-padding {
  padding: 0;
  min-height: 14vh;
}

/* @todo: find a way to solve this more elegant */
.ui.celled.grid > .column:not(.row),
.ui.celled.grid > .row > .column.no-min-height {
  min-height: 5vh;
  font-weight: 700;
  padding: 5px;
}

/* calendar days without month */
.calendar-day.notfirstday {
  width: 30.08px;
  height: 30.08px;
}

.ui.menu:not(.vertical) .right.item.no-padding-margin {
  margin: 0 !important;
  padding: 0 !important;
}

.ui.menu:not(.vertical) .right.item.no-margin-left {
  margin-left: 0 !important;
}

.ui.container.backend-container {
  margin-left: calc(var(auto) + 200px) !important;
  margin-top: calc(var(auto) + 50px) !important;
}

/* remove require asterisk */
.ui.form .required.field > .checkbox.remove-require:after {
  content: '';
}

/* custom border for calendar grid */
.ui.celled.grid.custom-border {
  background: white;
  border-radius: 0.28571429rem;
}

/* validation label */
label > .text.red {
  float: right;
}

/* Language Dropdown */
.ui.selection.dropdown.language-dropdown {
  background: #1b1c1d;
  min-width: 0em;
  transition: border 0.2s ease-in-out, background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}

.ui.selection.dropdown.language-dropdown.footer {
  border: 1px solid #333;
}

.ui.selection.dropdown.language-dropdown:hover,
.ui.selection.dropdown.language-dropdown:hover .menu,
.ui.selection.active.dropdown.language-dropdown:hover,
.ui.selection.active.dropdown.language-dropdown,
.ui.selection.dropdown.language-dropdown:focus {
  border-color: #333 !important;
  border: 1px solid #333;
  background-color: #38383a;
}

.ui.selection.active.dropdown.language-dropdown .menu {
  background-color: #38383a;
  border: 1px solid #333;
}

.ui.selection.dropdown.language-dropdown .menu > .item {
  border-top: none;
}

.ui.inverted.menu .header.item.no-margin-left {
  margin-left: 0 !important;
}
